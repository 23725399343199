import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static values = {
    seekTime: Number,
  };
  static targets = [
    "link",
    "videoFrame",
    "toggleVideoFrame",
    "showVideoToggle",
  ];

  connect() {
    this.showPreview();
  }

  hideVideoFrame(target = null) {
    target = target || this.videoFrameTarget;
    target.classList.add("hidden");
    target.parentElement.classList.add("hidden");
    target.parentElement.setAttribute("aria-hidden", true);
    target.src = "";
  }

  highlight({ detail: { operatorId } }) {
    this.element
      .querySelectorAll(".operators__highlightable")
      .forEach((card) => {
        card.classList.remove("outline", "outline-primary");
        if (card.dataset.operatorId == operatorId) {
          card.classList.add("outline", "outline-primary");
        }
      });
  }

  showPreview() {
    const result = this.embedLink();
    if (result) {
      if (this.hasShowVideoToggleTarget) this.showVideoToggleTarget.classList.remove("sm:hidden");
      if (this.isShowingOperatorVideo()) this.showOperatorVideo();
    } else {
      if (this.hasShowVideoToggleTarget) this.showVideoToggleTarget.classList.add("sm:hidden");
      this.hideOperatorVideo();
    }
  }

  embedLink(target = null, seek = null) {
    target = target || (this.hasVideoFrameTarget && this.videoFrameTarget);
    if (!target) return false;

    if (!this.linkTarget.value.match(/http.*youtube.*watch\?v=/)) {
      this.hideVideoFrame();
      return false;
    }

    target.classList.remove("hidden");
    target.parentElement.classList.remove("hidden");
    target.parentElement.removeAttribute("aria-hidden");

    const url = new URL(this.linkTarget.value);
    const videoId = new URLSearchParams(url.search).get("v");
    const embedUrl = `https://youtube.com/embed/${videoId}`;

    target.src = embedUrl;
    if (seek) target.src += `?start=${seek}&autoplay=1`;
    return true;
  }

  isShowingOperatorVideo() {
    return this.showVideoToggleTarget.querySelector("input").checked;
  }

  toggleShowVideo(event) {
    if (event.target.checked) {
      this.showOperatorVideo();
    } else {
      this.hideOperatorVideo();
    }
  }

  get formContainerSoleChildClasses() {
    return ["lg:!w-1/2", "lg:!h-fit"];
  }

  showOperatorVideo() {
    if (!this.hasToggleVideoFrameTarget) return;
    this.embedLink(this.toggleVideoFrameTarget, this.seekTimeValue);
  }

  hideOperatorVideo() {
    if (!this.hasToggleVideoFrameTarget) return;

    this.hideVideoFrame(this.toggleVideoFrameTarget);
  }
}
