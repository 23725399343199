import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.onInputHandler = this.onInput.bind(this);
    this.form.addEventListener("input", this.onInputHandler);
  }

  disconnect() {
    this.form.removeEventListener("input", this.onInputHandler);
  }

  get form() {
    return this.element;
  }

  onInput(event) {
    if (this.timeOut) clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.form.requestSubmit();
    }, 100);
  }
}
