import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ['choices']
  static targets = ['refreshBtn']


  initialize() {
    this.usedKeys = [
     "operator_id", "stage_id", "self_only", "favorited", "channel_id", "stageable_id", "excluded", "sort"
    ]
  }

  choicesOutletConnected(outlet) {
    outlet.element.addEventListener('change', () => {
      this.refreshBtnTarget.click()
    })
  }

  cleanUrl(e) {
    const formData = e.formData;
    const keysToDelete = []
    const keyValuePairToSet = []
    const keyIndexToSimpleIndex = {}
    Array.from(formData.entries()).forEach(([key, value], index) => {
      const usedKey = this.usedKeys.find((uk) => key.includes(uk))
      const keyIndex = key.match(/\[(\d+)\]/)?.[1]
      keysToDelete.push(key)
      if (!usedKey)
        return
      if (usedKey === 'operator_id') {
        keyValuePairToSet.push([`used_operators_attributes[${index}][${usedKey}]`, value])
        keyIndexToSimpleIndex[keyIndex] = index
      }
      else if (usedKey === "excluded")
        keyValuePairToSet.push([`used_operators_attributes[${keyIndexToSimpleIndex[keyIndex]}][${usedKey}]`, value])
      else
        keyValuePairToSet.push([usedKey, value])      
    })
    keyValuePairToSet.forEach(([key, value]) => formData.set(key, value))
    keysToDelete.forEach((key) => formData.delete(key))
  }
}