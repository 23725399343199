// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

import "@hotwired/turbo-rails";
import { StreamActions } from "@hotwired/turbo";
import "~/controllers";


StreamActions.focus = function () {
  setTimeout(
    () => {
      document
        .querySelector(`#${this.getAttribute("target")}`)?.focus();
    }, 100
  )
}

StreamActions.hide = function () {
  document
    .querySelector(`#${this.getAttribute("target")}`)
    ?.classList?.add("hidden");
};

StreamActions.unhide = function () {
  document
    .querySelector(`#${this.getAttribute("target")}`)
    ?.classList?.remove("hidden");
};
// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwire d/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// TODO: listen to turbo:before-cache and cleanup form data
